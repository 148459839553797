import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { LogoRegion as Logo } from "./logo";
const localStorageRegion = JSON.parse(localStorage.getItem("spoilerRegion"));

function NavbarMobile({ leadsFoodUrl, leadsFoodEventHash }) {
  const [dropdownMenu, setDropdownMenu] = useState(false);

  function toggleMenu() {
    setDropdownMenu(!dropdownMenu);
  }

  function Link({ label, slug }) {
    return (
      <NavLink
        activeClassName="Navbar__Item--active"
        to={`/${slug}`}
        className="Navbar__Item"
        onClick={toggleMenu}
      >
        {label}
      </NavLink>
    );
  }

  return (
    <nav className="d-flex d-lg-none">
      <div className="Navbar__Header">
        <NavLink
          to={`/${localStorageRegion?.slug}/home`}
          className="Navbar__Logo"
        >
          <Logo />
        </NavLink>
        <div className="Navbar__Toggle" onClick={toggleMenu} />
      </div>
      <div className={`Navbar__Items ${dropdownMenu ? "open" : " closed"}`}>
        <Link label="Home" slug="home" />
        <Link label="Sobre" slug="sobre" />
        <a
          href={`${leadsFoodUrl}/reservation`}
          rel="noreferrer"
          className="Navbar__Item"
        >
          Reservas
        </a>
        <a
          href={`${leadsFoodUrl}/menu`}
          rel="noreferrer"
          className="Navbar__Item"
        >
          Cardápio
        </a>
        <a
          href={`${leadsFoodUrl}/menu/${leadsFoodEventHash}`}
          rel="noreferrer"
          className="Navbar__Item"
        >
          Agenda
        </a>
        <Link label="Contato" slug="contato" />
      </div>
    </nav>
  );
}

export default NavbarMobile;
