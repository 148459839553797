import React, { useEffect, useContext } from "react";
import DesktopNav from "./desktop";
import MobileNav from "./mobile";
import { Logo } from "./logo";
import { RegionContext } from "../../contexts/region.context";
import { withRouter } from "react-router-dom";

export default withRouter(({ history }) => {
  const {
    state: { region },
    dispatch,
  } = useContext(RegionContext);
  const {
    state: {
      region: { id: regionId },
    },
  } = useContext(RegionContext);

  useEffect(() => {
    const isOnRegionSelecion = history.location.pathname === "/onde-voce-esta";
    const localStorageRegion = JSON.parse(
      localStorage.getItem("spoilerRegion")
    );
    if (!localStorageRegion) {
      return history.push("/onde-voce-esta");
    }
    const hasOldRegionFormat =
      !localStorageRegion.hasOwnProperty("phonePrefix");

    if (!isOnRegionSelecion && hasOldRegionFormat) {
      history.push("/onde-voce-esta");
    } else if (region.id === 0 && !isOnRegionSelecion) {
      dispatch({ type: "SET_REGION", payload: localStorageRegion });
    }
  }, [dispatch, history, region.id]);

  const isSp = regionId === 34;
  const hashSp = "9cded8cb-d707-4d5b-9158-6c85829f0bb4";
  const hashPoa = "9cdea2fe-74ee-4bb1-857c-a2dfc7f3c294";
  const hash = isSp ? hashSp : hashPoa;
  const mainUrl = `https://spoiler${isSp ? "sp" : "bar"}.leadsfood.app`;
  return (
    <header className="Navbar">
      <div className="container">
        {region.id ? (
          <>
            <DesktopNav leadsFoodUrl={mainUrl} leadsFoodEventHash={hash} />
            <MobileNav leadsFoodUrl={mainUrl} leadsFoodEventHash={hash} />
          </>
        ) : (
          <nav className="">
            <Logo />
          </nav>
        )}
      </div>
    </header>
  );
});
