import React, { Component } from "react";
import axios from "axios";
import Related from "./Related";

class Post extends Component {
  state = {
    _embedded: {},
    loading: true,
  };

  get title() {
    let { title: { rendered: title } = { rendered: "" } } = this.state;
    return title;
  }

  get subtitle() {
    let { excerpt: { rendered: subtitle } = { rendered: "" } } = this.state;
    return subtitle;
  }

  get media() {
    let { _embedded } = this.state;
    let featuredmedia = _embedded["wp:featuredmedia"] || [];
    let [
      {
        media_details: {
          sizes: {
            large: { source_url: media },
          },
        },
      } = {
        media_details: {
          sizes: {
            large: {
              source_url: "",
            },
          },
        },
      },
    ] = featuredmedia;
    return media;
  }

  get content() {
    let { content: { rendered: content } = { rendered: "" } } = this.state;
    return content;
  }

  get data() {
    let { title, subtitle, media, content, tags } = this;
    return { title, subtitle, media, content, tags };
  }

  fetchData(callback) {
    this.setState({ loading: true });
    let {
      match: {
        params: { id },
      },
    } = this.props;
    axios
      .get(`${process.env.REACT_APP_API_URL}/wp/v2/posts/${id}`, {
        params: {
          _embed: "",
        },
      })
      .then(({ data }) => this.setState({ ...data }))
      .then(callback)
      .then(() => this.setState({ loading: false }));
    return this;
  }

  componentDidMount() {
    this.fetchData(() => window.scrollTo(0, 0));
  }
  componentDidUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.fetchData(() => window.scrollTo(0, 0));
    }
  }
  render() {
    let { title, subtitle, media, content } = this.data;
    return (
      <div className="BlogDetailPage">
        {!this.state.loading ? (
          <div className={this.state.loading ? "loading" : ""}>
            <section className="HeaderBanner">
              <div className="container">
                <div className="row">
                  <div className="slick-slide d-block">
                    <img src={media} alt="" className="img-fluid" />
                    <div className="subtitle">
                      <span dangerouslySetInnerHTML={{ __html: title }} />
                      <b dangerouslySetInnerHTML={{ __html: subtitle }} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div
              className="container mt-4 pt-4"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        ) : (
          <p className="text-center mt-5 mb-5">Carregando...</p>
        )}
        <section className="RelatedPosts">
          <Related tags={this.state.tags} />
        </section>
      </div>
    );
  }
}

export default Post;
