import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { RegionContext } from "../../contexts/region.context";
import Loader from "../../components/Loader";
const Region = withRouter(({ history }) => {
  const [fetchedRegions, setFetchedRegions] = useState([]);
  const { dispatch } = useContext(RegionContext);

  function setRegion(region) {
    dispatch({ type: "SET_REGION", payload: region });
    localStorage.setItem("spoilerRegion", JSON.stringify(region));
  }

  function fetchRegions(callback) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/wp/v2/region`)
      .then(({ data }) => {
        const allowedRegions = ["saopaulo", "portoalegre", "florianopolis"];
        const filteredRegions = data.filter((item) =>
          allowedRegions.some((region) => region === item.slug),
        );
        setFetchedRegions(filteredRegions);
      })
      .then(callback);
    return this;
  }

  useEffect(() => {
    dispatch({ type: "CLEAN_REGION" });
    fetchRegions(() => window.scrollTo(0, 0));
    return () => {};
  }, [dispatch]);

  return (
    <div className="Region">
      <div className="container mt-2  d-flex flex-column">
        <h2 className="special">Onde você está?</h2>
        <div className="Region__Items">
          {!!fetchedRegions.length ? (
            fetchedRegions.map(
              ({ id, name, slug, acf: { tripadvisorid, phoneprefix } }) => {
                const regionInfo = {
                  id,
                  name,
                  slug,
                  tripAdvisorId: tripadvisorid,
                  phonePrefix: phoneprefix,
                };

                return (
                  <span
                    className="Region__Item"
                    key={id}
                    onClick={() => setRegion(regionInfo)}
                  >
                    {name}
                  </span>
                );
              },
            )
          ) : (
            <Loader loading />
          )}
        </div>
      </div>
    </div>
  );
});

export default Region;
