import React, { Component } from "react";
import axios from "axios";
import PostCard from "../../components/PostCard";

class RelatedPosts extends Component {
  state = {
    items: [],
    posts: [],
    loading: true,
  };

  constructor(props) {
    super(props);
    this.fetchPosts = this.fetchPosts.bind(this);
  }
  componentDidUpdate() {
    this.state.loading && this.fetchPosts(this.props.tags);
  }
  shouldComponentUpdate(nextState) {
    if (this.state !== nextState) {
      return true;
    }
  }

  fetchPosts(callback) {
    let options = {
      params: {
        tags: this.props.tags,
        per_page: 100,
        _embed: "",
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/wp/v2/posts`, options)
      .then(({ data: posts = [] }) => this.setState({ posts }))
      .then(callback);
    return this;
  }

  render() {
    let { posts } = this.state;
    return (
      <div className="AboutPage">
        {posts.length ? (
          <div className="container mt-5 pt-5">
            <div className="row">
              <h2>Posts Relacionados</h2>
            </div>
            <div className="row">
              {posts.map((post, key) => (
                <PostCard key={key} {...post} />
              ))}
            </div>
          </div>
        ) : (
          <p className="text-center mt-5 mb-5">Carregando...</p>
        )}
      </div>
    );
  }
}

export default RelatedPosts;
