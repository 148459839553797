import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import HeaderBanner from "../../components/HeaderBanner";
import Loader from "../../components/Loader";

import { MenuContextProvider, MenuContext } from "./MenuContext";
import { RegionContext } from "../../contexts/region.context";
import MenuCategory from "./MenuCategory";
import MenuNavigation from "./MenuNavigation";

function SubCategoriesComponent({ id, title }) {
  const { state } = useContext(MenuContext);
  const shouldRender = state.visibleItems.includes(title);
  const items = state.menuCategories.filter(
    (category) => category.parent === id
  );
  return (
    shouldRender && (
      <div className="row">
        {items.map((item) => {
          const content = state.menu.filter((menuItem) =>
            menuItem.menu_category.includes(item.id)
          );
          return content.length ? (
            <MenuCategory
              key={item.id}
              title={item.name}
              content={content}
              thumb={item.acf.imagem}
            />
          ) : null;
        })}
      </div>
    )
  );
}
const SubCategories = React.memo(SubCategoriesComponent);

function CategoryComponent({ id, name }) {
  return <SubCategories title={name} id={id} />;
}
const Category = React.memo(CategoryComponent);

function fetchMenu(region, callback, setLoading) {
  if (!region) return;
  let options = {
    params: {
      per_page: 100,
      _embed: "",
    },
  };
  try {
    axios
      .get(`${process.env.REACT_APP_API_URL}/wp/v2/menu_category`, options)
      .then(({ data: menu_category = [] }) => {
        let visibleItems = [];
        menu_category.map((category) => visibleItems.push(category.name));

        callback({ type: "SET_VISIBLE_ITEMS", payload: visibleItems });
        callback({ type: "SET_MENU_CATEGORIES", payload: menu_category });
        setLoading(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/wp/v2/menu?region=${region}`,
        options
      )
      .then(({ data: menu = [] }) => {
        callback({ type: "SET_MENU", payload: menu });
      });
  } catch (error) {
    console.log(error);
  }
  return this;
}

function Menu() {
  const {
    state: { region },
  } = useContext(RegionContext);
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useContext(MenuContext);
  const shouldRender = !!state.menuCategories.length && !!state.menu.length;

  useEffect(() => {
    alert(dispatch);
    if (!region.id) return;
    fetchMenu(region.id, dispatch, setLoading);
  }, [region, dispatch]);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {shouldRender && <MenuNavigation />}
            {shouldRender &&
              state.menuCategories.map((category) => {
                const { name, id, parent } = category || {};

                if (parent !== 0) return false;

                return <Category key={id} id={id} name={name} />;
              })}
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <Loader loading={loading} />
      </div>
    </>
  );
}

function ContextualizedMenu() {
  const location = useLocation();
  return (
    <div className="MenuPage">
      <HeaderBanner path={location.pathname} hideLoader />
      <MenuContextProvider>
        <Menu />
      </MenuContextProvider>
    </div>
  );
}

export default ContextualizedMenu;
