import React, { createContext, useReducer } from "react";

const initialState = {
  region: {
    name: "",
    slug: "",
    tripadvisorid: "",
    phoneprefix: "",
    id: 0,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_REGION":
      return { ...state, region: action.payload };
    case "CLEAN_REGION":
      localStorage.removeItem("spoilerRegion");
      return { ...state, ...initialState };
    default:
      return { ...state };
  }
};

export const RegionContext = createContext({});

export function RegionContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <RegionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </RegionContext.Provider>
  );
}
