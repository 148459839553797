import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class PostCard extends Component {
  get title() {
    let { title: { rendered: title } = { rendered: "" } } = this.props;
    return title;
  }

  get subtitle() {
    let { excerpt: { rendered: subtitle } = { rendered: "" } } = this.props;
    return subtitle;
  }

  get media() {
    let { _embedded } = this.props;
    let featuredmedia = _embedded["wp:featuredmedia"] || [];
    let [
      {
        media_details: {
          sizes: {
            large: { source_url: media },
          },
        },
      } = {
        media_details: {
          sizes: {
            large: {
              source_url: "//picsum.photos/1024/768",
            },
          },
        },
      },
    ] = featuredmedia;
    return media;
  }

  get data() {
    let { title, subtitle, media } = this;
    let { id, slug } = this.props;
    return { title, subtitle, id, slug, media };
  }

  render() {
    let { title, subtitle, id, slug, media } = this.data;
    return (
      <div className="Blog__Excerpt col-12 col-sm-6 col-md-4">
        <NavLink
          activeClassName="Blog__Excerpt--active"
          to={`/post/${id}/${slug}`}
          className="Blog__Excerpt"
        >
          <img src={media} className="img-fluid" alt="" />
          <span
            className="Blog__Excerpt-Title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <span
            className="Blog__Excerpt-Subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </NavLink>
      </div>
    );
  }
}

export default PostCard;
