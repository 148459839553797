import React, { useContext } from "react";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import TagManager from "react-gtm-module";

import Navbar from "./components/Navbar";
import { RegionContext } from "./contexts/region.context";

import Footer from "./components/Footer";

import Home from "./pages/Home";
import About from "./pages/About";
import Booking from "./pages/Booking";
import Menu from "./pages/Menu";
import Contact from "./pages/Contact";
import Post from "./pages/Post";
import Region from "./pages/Region";
import Calendar from "./pages/Calendar";

function usePageViews() {
  const location = useLocation();
  React.useEffect(() => {
    const dataLayerArgs = {
      dataLayer: {
        page: location.pathname + location.search,
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(dataLayerArgs);
  }, [location]);
}

function App() {
  const {
    state: { region },
  } = useContext(RegionContext);
  const localStorageRegion = JSON.parse(localStorage.getItem("spoilerRegion"));
  usePageViews();
  const hasSelectedRegion = localStorageRegion || region.id;
  const allowedRegions = ["saopaulo", "portoalegre"];
  const hasAllowedRegion = allowedRegions.some(
    (region) => region === localStorageRegion?.slug
  );

  return hasSelectedRegion && hasAllowedRegion ? (
    <>
      <Navbar />
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/sobre" component={About} />
        {/* <Route path="/reservas" component={Booking} /> */}
        {/* <Route path="/cardapio" component={Menu} /> */}
        {/* <Route path="/agenda" component={Calendar} /> */}
        {/* <Route path="/post/:id/:slug" component={Post} /> */}
        <Route path="/contato" component={Contact} />
        <Redirect from="/" to="/home" />
      </Switch>
      <Footer />
    </>
  ) : (
    <Region />
  );
}
function AppRouter() {
  return (
    <Router basename="/">
      <App />
    </Router>
  );
}

export default AppRouter;
